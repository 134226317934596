import {SET_ASSET_CATEGORIES, SET_ASSETS, SET_DETAILED_ASSET} from "@/store/assets/mutations";
import apiHandler from "@/utils/handlers/ApiHandler";
import ApiHandler from "@/utils/handlers/ApiHandler";
import Asset from "@/models/Asset";
import assetCategories from "@/utils/stubData/assetCategories.json";

export const FETCH_ASSETS = "fetchAssets";
export const FETCH_DETAILED_ASSET = "fetchDetailedAsset";
export const ADD_ASSET = "addAsset";
export const EDIT_ASSET = "editAsset";
export const DELETE_ASSET = "deleteAsset";
export const FETCH_ASSET_CATEGORIES = "fetchAssetCategories";

export const actions = {
    async [FETCH_ASSETS]({commit}) {
        const res = await apiHandler.get('/asset');
        if (ApiHandler.isSuccess(res.status)) {
            commit(SET_ASSETS, res.data);
            return true;
        }
        return false;
    },
    async [FETCH_DETAILED_ASSET]({commit}, assetId) {
      const res = await apiHandler.get(`/asset/${assetId}`);
      if (ApiHandler.isSuccess(res.status)) {
          commit(SET_DETAILED_ASSET, new Asset(res.data));
          return true;
      }
      return false;
    },
    async [FETCH_ASSET_CATEGORIES]({commit}) {
        const res = await apiHandler.get("/asset/category");
        if (ApiHandler.isSuccess(res.status)) {
            commit(SET_ASSET_CATEGORIES, res.data);
            return true;
        }
        commit(SET_ASSET_CATEGORIES, assetCategories);
        return false;
    },
    async [ADD_ASSET]({commit}, formData) {
        const res = await apiHandler.post("/asset", formData, apiHandler.getAuthHeader());
        return ApiHandler.isSuccess(res.status);
    },
    async [EDIT_ASSET]({commit}, {formData, id}) {
        const res = await apiHandler.post(`/asset/${id}`, formData, apiHandler.getAuthHeader());
        return ApiHandler.isSuccess(res.status);
    },
    async [DELETE_ASSET](_, assetId) {
        const res = await apiHandler.delete(`/asset/${assetId}`, {}, apiHandler.getAuthHeader());
        return apiHandler.isSuccess(res.status);
    }
}