
export default class Faq {
    constructor({ id, question, answerHtml, faqCategoryId }) {
        this.id = id;
        this.question = question;
        this.answerHtml = answerHtml;
        this.faqCategoryId = faqCategoryId;
    }

    /** @type number */
    id;
    /** @type string */
    question;
    /** @type string */
    answerHtml;
    /** @type number */
    faqCategoryId;
}