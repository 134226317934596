import {getters} from "./getters";
import {actions} from "./actions";
import {mutations} from "./mutations";

export const state = () => ({
    assets: [],
    detailedAsset: null,
    assetCategories: []
})

const namespaced = false;

export const assets = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}