
export const GET_ASSETS = "getAssets";
export const GET_DETAILED_ASSET = "getDetailedAsset";
export const GET_ASSET_CATEGORIES = "getAssetCategories";

export const getters = {
    [GET_ASSETS](state) {
        return state.assets;
    },
    [GET_DETAILED_ASSET](state) {
        return state.detailedAsset;
    },
    [GET_ASSET_CATEGORIES](state) {
        return state.assetCategories;
    }
}