
export default class Asset {
    /**
     * @param id {number},
     * @param title {string},
     * @param subtitle {string},
     * @param assetStoreUrl {string},
     * @param introductionHtml {string},
     * @param features {string},
     * @param technicalDetails {string},
     * @param seoDescription {string},
     * @param assetCategoryId {number},
     * @param thumbnailPath {string},
     * @param seoThumbnailPath {string},
     * @param demoUpdatedAt {string},
     * @param demoPath {string},
     * @param category {{id: number, name: string}},
     * @param mediaFiles {{id: number, mediaPath: string}[]}
     */
    constructor({id, createdAt, title, subtitle, assetStoreUrl, introductionHtml, features, technicalDetails, seoDescription, assetCategoryId, thumbnailPath, seoThumbnailPath, demoPath, demoUpdatedAt, category, mediaFiles}) {
        this.id = id;
        this.createdAt = createdAt;
        this.title = title;
        this.subtitle = subtitle;
        this.assetStoreUrl = assetStoreUrl;
        this.introductionHtml = introductionHtml;
        this.features = features;
        this.technicalDetails = technicalDetails;
        this.seoDescription = seoDescription;
        this.assetCategoryId = assetCategoryId;
        this.thumbnailPath = thumbnailPath;
        this.seoThumbnailPath = seoThumbnailPath;
        this.demoPath = demoPath;
        this.demoUpdatedAt = demoUpdatedAt;
        this.category = category;
        this.mediaFiles = mediaFiles;
    }

    /** @type number */
    id;
    /** @type string */
    createdAt;
    /** @type string */
    title;
    /** @type string */
    subtitle;
    /** @type string */
    assetStoreUrl;
    /** @type string */
    introductionHtml;
    /** @type string */
    features;
    /** @type string */
    technicalDetails;
    /** @type string */
    seoDescription;
    /** @type number */
    assetCategoryId;
    /** @type string */
    thumbnailPath;
    /** @type string */
    seoThumbnailPath;
    /** @type string */
    demoPath;
    /** @type string */
    demoUpdatedAt;
    /** @type {{id: number, name: string}} */
    category;
    /** @type {{id: number, mediaPath: string}[]} */
    mediaFiles;
}
