
export const GET_FAQS = "getFaqs";
export const GET_FAQ = "getFaq";

export const getters = {
    [GET_FAQS](state) {
        return state.faqs;
    },
    [GET_FAQ](state) {
        return state.faq;
    }
}