
export const SET_ASSETS = "setAssets";
export const SET_DETAILED_ASSET = "setDetailedAsset";
export const SET_ASSET_CATEGORIES = "setAssetCategories";

export const mutations = {
    [SET_ASSETS](state, payload) {
        state.assets = payload;
    },
    [SET_DETAILED_ASSET](state, payload) {
        state.detailedAsset = payload;
    },
    [SET_ASSET_CATEGORIES](state, payload) {
        state.assetCategories = payload;
    }
}