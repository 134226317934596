import AuthGuard from '@/router/guards/auth'
import AdminGuard from '@/router/guards/admin'

const Layout = () => import('../../views/Layout.vue')
const Overview = () => import('../../views/faq/Overview.vue')
const Details = () => import('../../views/faq/Edit')

export const ROUTE_NAMES_FAQ = Object.freeze({
  OVERVIEW: 'FaqOverview',
  DETAILS: 'FaqDetails'
});

export const faq = {
  path: '/faq',
  name: ROUTE_NAMES_FAQ.OVERVIEW,
  redirect: '/faq/overview',
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_FAQ.OVERVIEW,
      component: Overview
    },
    {
      path: 'details/:id?',
      name: ROUTE_NAMES_FAQ.DETAILS,
      component: Details
    }
  ]
}
