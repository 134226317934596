import {getters} from "./getters";
import {actions} from "./actions";
import {mutations} from "./mutations";

export const state = {
    faq: null,
    faqs: null,
}

const namespaced = false;

export const faqs = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}