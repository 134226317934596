
export const SET_FAQS = "setFaqs";
export const SET_FAQ = "setFaq";

export const mutations = {
    [SET_FAQS](state, payload) {
        state.faqs = payload;
    },
    [SET_FAQ](state, payload) {
        state.faq = payload;
    }
}
