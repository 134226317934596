import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import BaseGuard from "@/router/guards/base";

// todo: update the allowed type according to your need
const TYPE_ADMIN = 3;

class AdminGuard extends BaseGuard {
  constructor() {
    super();
    this.typeAllowed = TYPE_ADMIN;
  }

  async handleRouteEnter() {
    if (!LocalDataHandler.getAccessToken()) {
      return this.refreshUserProfile().then(response => {
        if (response) {
          return this.checkUserType() ? true : this.routeOnFailure;
        }
        /** keep in mind that the catch is handled in base guard (base.js) **/
        return this.routeOnFailure;
      });
    }
    return new Promise(resolve => {
      this.checkUserType() ? resolve(true) : resolve(this.routeOnFailure);
    });
  }

  checkUserType() {
    // todo: update the implementation according to your need
    const activeUser = LocalDataHandler.getUser();
    return !!activeUser && activeUser.type === this.typeAllowed;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AdminGuard();
    }
    return this.instance;
  }
}

export default AdminGuard.getInstance();