import apiHandler from '@/utils/handlers/ApiHandler';
import {SET_FAQ, SET_FAQS} from "@/store/faqs/mutations";
import faqs from "@/utils/stubData/faqs.json";
import Faq from "@/models/Faq";

export const FETCH_FAQS = "fetchFaqs";
export const FETCH_FAQ = "fetchFaq";
export const FETCH_FAQ_BY_ID = "fetchFaqsById";
export const CREATE_FAQ = "createFaq";
export const EDIT_FAQ = "editFaq";
export const DELETE_FAQ = "deleteFaq";


export const actions = {
    /** Get all faqs **/
    [FETCH_FAQS]({commit}) {
        return apiHandler.get('faq', apiHandler.getAuthHeader()).then(response => {
        if (apiHandler.isSuccess(response.status)) {
            console.log('faqs are fetched')
            commit(SET_FAQS, response.data.map(faqItem => new Faq(faqItem)));
            return true;
        }
        return false;
        });
    },
    /** Get faq by id **/
    [FETCH_FAQ_BY_ID]({commit}, faqId) {
        return apiHandler.get(`faq/${faqId}`, apiHandler.getAuthHeader()).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                commit(SET_FAQ, new Faq(response.data));
                return true;
            }
            return false;
        });
    },
    /** Create faq **/
    [CREATE_FAQ]({commit}, data) {
        return apiHandler.post('faq', data, apiHandler.getAuthHeader()).then(response => {
        if (apiHandler.isSuccess(response.status)) {
            return true;
        }
        return false;
        });
    },
    /** Edit faq **/
    [EDIT_FAQ]({commit}, data) {
        console.log('payload inside actions', data);
        return apiHandler.put(`faq/${data.id}`, data, apiHandler.getAuthHeader()).then(response => {
        if (apiHandler.isSuccess(response.status)) {
            return true;
        }
        return false;
        });
    },
    /** Delete faq **/
    [DELETE_FAQ]({commit}, id) {
        return apiHandler.delete(`faq/${id}`, null, apiHandler.getAuthHeader()).then(response => {
        if (apiHandler.isSuccess(response.status)) {
            return true;
        }
        return false;
        });
    }
}
