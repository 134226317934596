import {createStore} from 'vuex';
import {mutations} from './mutations';
import {actions} from './actions';
import {getters} from './getters';

import {auth} from './auth';
import {assets} from './assets';
import {faqs} from './faqs';

export const state = () => ({
  isApiLoading: false
});

const namespaced = false;

const store = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    assets,
    faqs
  }
}

// create the store
const vuexStore = createStore(store);

export default vuexStore;
