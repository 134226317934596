import AuthGuard from '@/router/guards/auth'
import AdminGuard from '@/router/guards/admin'

const Layout = () => import('../../views/Layout.vue')
const Overview = () => import('../../views/assets/Overview')
const Details = () => import('../../views/assets/Edit')

export const ROUTE_NAMES_ASSETS = Object.freeze({
  OVERVIEW: 'AssetsOverview',
  DETAILS: 'AssetsDetails'
});

export const assets = {
  path: '/',
  redirect: '/overview',
  name: ROUTE_NAMES_ASSETS.OVERVIEW,
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_ASSETS.OVERVIEW,
      component: Overview
    },
    {
      path: 'details/:id?',
      name: ROUTE_NAMES_ASSETS.DETAILS,
      component: Details
    }
  ]
}
